.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  text-decoration: none;
  color:rgba(0, 0, 0, 0.87);
}

a:hover, a:focus{
  color:#ffc107;
}

.logo {
  height: 70px;
}

.login-page {
  margin-top: 10%;
}

.inside-logo {
  height: 35px;
}

.tag-categoria {
  font-size: 11px;
  padding: 4px 10px;
  line-height: 10px;
  margin: 0px 5px;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  border-radius: 4px;
  margin-top: 10px;
}

.link {
  text-decoration: none;
}

.prazo {
  line-height: 14px;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 3px;
  color: #888;
}

.title-checklist {
  color: #888;
  font-size: 14px;
  font-weight: 600;
}

.anexo {
  background-color: #e0e0e0;
  border-radius: 3px;
  line-height: 21px;
  transition: all 0.2s ease-out 0s;
  padding: 8px 15px;
  border-width: 0;
  display: block;
    width: 90%;
    display: inline-block;
    /* padding: 6px 12px; */
    /* margin-bottom: 0; */
    font-size: 14px;
    font-weight: 400;
    /* line-height: 1.42857143; */
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    
}

.previewAnexos{
  margin-bottom: 5px;
}

.viewSplash{
  width: 100%;
  height: 100%;
  position: relative;
}

.viewSplash, .img-splash{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}
